<template>
  <div class="stakewait">
    <div v-show="show == 1">


    <div class="top_button">
        <div class="coinInfomation" >
          <div class="coinImg"><img :src="withdraw" class="dailogo" /></div>
          <div class="coinInfo">{{$t("Withdraw.Withdraw")}}</div>
          <!-- <img :src="swap_arrow" class="swaparrow" /> -->
        </div>
    </div>


    <div class="ownwalletInfo2 top20">
      <div class="ownTips2">{{$t("Withdraw.Availableassets")}}:</div>
      <div class="ownMoney2">
      $ {{ getDepositDatadata[0] | fildecimals }}<img class="daimi2" :src="swap_aimg"/>
      </div>
    </div>

    <div class="ownwalletInfo2">
      <div class="ownTips2">{{$t("Withdraw.LockedAassets")}}:</div>
      <div class="ownMoney2">
      $ {{ getDepositDatadata[1] | fildecimals }}<img class="daimi2" :src="swap_aimg"/>
      </div>
    </div>

      <Divider style="background: rgba(142, 200, 243, 0.5)" />

    <!--info start-->
    <div class="infoContent">
      <div class="infoImg"> <img src="../assets/img/info.svg" alt=""></div>
      <div class="infoText">{{ $t("Withdraw.forzen") }}
        <router-link to="/LockedAssets">
          <span class="more">More</span> 
        </router-link>
      </div>
     
    </div>
    <!--info end-->

      <div class="inputInfo" >
        <input v-model.number="daiValue" type="number" class="inputStyle" @input="inputclick()" />
        <button @click="daiValue = getDepositDatadata[0], inputclick()" class="tbnStyle ripple">
          Max
        </button>
      </div>

      <div class="StakeAuthor2">
        <div class="Author2">
          <button class="StakeBtn2 ripple" @click="withdraw()">
          {{ $t("Withdraw.Withdraw") }}
        </button>
        </div>
      </div>
    <!--info start-->
    <div class="infoContent top20">
      
      <div class="infoText">* {{ $t("Withdraw.WithdrawInfo4") }}</div>
    </div>
    <!--info end-->
    
    <div class="title top20">{{$t("Withdraw.EarningsTitle")}}:</div>
        
    <div class="ownwalletInfo2">
      <div class="ownTips2x">{{$t("Withdraw.basicEarnings")}}:</div>
      <div class="ownMoney2">
      $ {{ getMyUserInterestdata | fildecimals }}<img class="daimi2" :src="swap_aimg"/>
      </div>
    </div>


    <div class="stylea"><img :src="stylea" alt=""></div>
 </div>

    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg" />
    </div>
    <!--loading end-->
    <div class="selectCoin" v-show="isShown2">
      <div class="tipsandclose">
        <div class="t1">{{ $t("swap.recommend") }}（Polygon）</div>
        <div class="closeimg"><img :src="swap_close" alt="" @click="isShown2 = !isShown2"></div>
      </div>
      <div class="allconin">
        <div class="coinBTN" @click="onselect(swap_usdt, 'USDT', 'USDT_swap', '6')">
          <img :src="swap_usdt" alt="">
          <div class="coiname">USDT</div>
        </div>
        <div class="coinBTN" @click="onselect(swap_usdc, 'USDC', 'USDC_swap', '6')">
          <img :src="swap_usdc" alt="">
          <div class="coiname">USDC</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/css/EvWithdraw.css"></style>
<style lang="less" scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@100;200&display=swap');
.top20{
  margin-top: 20px;
}

.StakeAuthor2{
  display: flex;
  gap: 5px;
  .Author2,.Stakeing2{
    width: 100%;
    height: 49px;    
     .enabled-class2{
      width: 100%;
      height: 45px;
      line-height: 45px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #322828;
      font-size: 16px;
      background: url("../assets/img/btnBgNoSelect.svg") no-repeat center center/cover;
     }
     .StakeBtn2{
      width: 100%;
      height: 45px;
      line-height: 45px;
      border-radius: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      color: #020000;
      font-size: 16px;
      background: url("../assets/img/btnBgSelect.svg") no-repeat center center/cover;
     }
  }

}
.title{
  font-size: 16px;
  border-bottom: 1px solid rgb(249 249 249 / 29%);
  padding-bottom: 10px;
  margin-top: 30px;
  margin-bottom: 5px;
  font-weight: 800;
}
.infoContent {
  display: flex;   
  align-items: left; 
  justify-content: left; 
  gap: 5px; 
  text-align: left; 
  font-size: 14px;  
  height: 100%;
  margin-bottom: 20px;
  .infoImg{    
    img{
      position: relative;
      top: 3px;  
      width: 14px ;
      height: 14px ;
    }
  }
  .infoText{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    .more{
      color: white;
      text-decoration: underline;
    }
  }
}
.ownwalletInfo2{
  display: flex;

  .ownTips2{
    font-size: 16px;    
    flex: 1;   
  }
  .ownTips2x{
    font-size: 14px;    
    flex: 1;   
  }
  .ownMoney2 {
  font-size: 17px;
  font-family: 'Source Sans Pro', sans-serif;
  }
  .ownMoney2x {
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  }
  .times2{
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  .ownMoney2 img.daimi2 {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  position: relative;
  top: 4px;
}
}
.stylea img{
  margin-top: 20px;
  width: 277px;
  height: 77px;
}
.WithDrawBtn {
  display: flex;
  justify-content: space-between;

  .tbnStyle3 {
    width: 134px;
    margin-bottom: 15px;
    height: 50px;
    line-height: 50px;
    position: relative;
    color: rgb(9, 0, 0);
    font-weight: bolder;
    top: 8px;
    border: none;
    outline: none;
    font-size: 20px;
    border-radius: 8px;
    padding: 0 10px;
    cursor: pointer;
    background-image: linear-gradient(126deg,
        rgba(209, 209, 209, 0.3),
        rgba(208, 208, 208, 0.3));
  }

}

.slider {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-button {
  width: 25px;
  height: 25px;
  line-height: 21px;
  background-color: rgb(172, 7, 114);
  color: #fff;
  border: 2px solid white;
  border-radius: 50px;
  text-align: center;
  font-size: 12px;
}

.tbnStyle2_div {
  background-color: rgb(3, 198, 101);
  color: #fff;
  width: 35px;
  height: 20px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  border-radius: 5px;
  margin-right: 5px;

}

.fanhui {
  display: flex;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
}

.fanhui img {
  width: 16px;
  height: 16px;
}



.nowNumber {
  text-align: center;
}
.ownwalletInfo{
  margin-bottom: 10px;

}
.endTime {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #08ebeb;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .prompt{
    color: #f1f1f1;
    font-size: 14px;
    margin-top: 20px;
  }
</style>
<script>
import withdraw from "@/assets/img/withdraw.svg";
import swap_falcon from "@/assets/img/swap_falcon.svg";
import swap_close from "@/assets/img/swap_close.svg";
import swap_arrow from "@/assets/img/swap_arrow.svg";
import swap_speed from "@/assets/img/speed.svg";
import swap_crow from "@/assets/img/crow.svg";
import swap_dai from "@/assets/img/swap_dai.svg";
import swap_usdc from "@/assets/img/swap_usdc.svg";
import swap_usdt from "@/assets/img/swap_usdt.svg";
import web3Utils from "@/utils/web3Utils.js";
import Marquee from "@/components/Marquee.vue";
import dai from "@/assets/img/DaiStablecoin.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import loadingimg from "@/assets/img/loading.svg";
import siteapi from "@/json/siteapi.json";
import sign from "@/plugins/sign.js";
import Vue from "vue";
import { Slider } from 'vant';
import aaveChild from "@/plugins/aaveChild";

Vue.use(Slider);
import Plugens from "@/plugins/index";

export default {
  name: "EvWithdraw",
  data() {
    return {
      swap_falcon,
      withdraw,
      swap_close,
      swap_arrow,
      swap_dai,
      swap_crow,
      swap_speed,
      swap_usdc,
      swap_usdt,
      dai: dai,
      daimni: daimni,
      daiValue: 0,
      daiOwnMoney: 0,
      daiShareMoney: 0,
      daiTemeMoney: 0.0,
      daiTemeMoney2: 0,
      stylea: stylea,
      usersdata: {},
      typeextract: 0,
      typeReinvestment: 0,
      typeReceive: 0,
      loadingimg: loadingimg,
      isloading: false,
      swapimg2: "",
      swapname: "",
      swap_decimalsA: 18,
      isShown2: false,
      swap_Aname: "",
      swap_aimg: "",
      swap_Aaddress: "",
      tokenUsersinvestAmount: 0,
      show: 1,
      slidervalue: 1,
      burnGetMyUserBurnInterestvalue1: 0,
      burnGetMyUserBurnInterestvalue2: 0,
      burnGetMyUserBurnInterestvalue3: 0,
      burnGetMyUserBurnInterestvalue4: 0,
      burnLevel: 0,
      burnLeveltype: 0,
      daiOwnMoneyjh: 0,
      falconAmount: 0,
      daiValue2: 0,
      burnContractAddressvalue: "",
      falconshow: false,
      typeApprove: 0,
      typeBurn: 0,
      sliderdisabled: false,
      burnUsersvalue: 0,
      burnAmount: 0,
      countdown: '',
      countdown1: '',
      timer: null,
      timershow: false,
      finishshow: false,
      timershow1: false,
      finishshow1: false,
      endTime:0,
      endTime1:0,
      firingtype: true,
      daiValue2small: 0,
      lastcalltimevalue: 0,
      lastcalltimevalue1: 0,
      lastExtractTime: 0,
      getWithdrawFalconTokenfalconAmount: 0,
      tokenUsersFInvestAmount: 0,
      burnSecond:0,
      getMyUserBurnDayInterestdata:0,
      lpBurnDayUsersinvestAmountIsBurn:false,
      toptypeshow:true,
      usdtinvestAmount:0,
      DAIinvestAmount:0,
      falconTokenUsersRewardAmount: 0,
      getDepositDatadata:[
        0,0,0,
      ],
      getMyUserInterestdata:0

    };
  },
  components: {
    Marquee,

  },
  filters: {

    fildecimals(e) {
      const multipliedNumber = e * 10000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 10000;
      //    console.log(result);

      let value = parseFloat(result);
      return value;
    },

  },
  async mounted() {
    // Determine whether the wallet is connected
    if (typeof window.ethereum !== 'undefined') {
      //console.log("Connected wallet");

      // Asynchronously obtaining network information
      window.ethereum
        .request({ method: 'eth_chainId' })
        .then((chainId) => {
          // Determine whether the current network is a Polygon chain
          if (chainId === '0x89') {
            //console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //console.log("The current network is not Polygon chain");
            this.$router.push({ path: '/' });
            return
          }
        })
        .catch((error) => {
          //console.error('Failed to obtain network information. Procedure:', error);
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi
    const currentAddress = await web3Utils.connectWallet();
    //console.log(currentAddress)
    this.addresscode = currentAddress
    if (currentAddress) {
      // this.daiOwnMoney = parseFloat(
      //   await web3Utils.getDaiBalance(currentAddress)
      // );

      // console.log(this.daiOwnMoney.toFixed(6));
      this.signtype()
      // this.exchangeallowance()
    }
  },
  methods: {
    funlastcalltime() {
      const now = Date.now();
      const date = this.lastcalltimevalue * 1 + this.lastExtractTime * 1000

      console.log(date, '22222222222222222222', this.lastExtractTime, this.lastcalltimevalue)
      // 用法示例

      const storedEndTime = date
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
      // localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown(date);
      // setInterval(this.updateCountdown, 1000);
      this.timer = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown(date)
      }, 1000)
    },
    funlastcalltime1() {
      const now = Date.now();
      const date = this.lastcalltimevalue1 * 1 + this.lastExtractTime * 1000

      console.log(date, '22222222222222222222', this.lastExtractTime, this.lastcalltimevalue1)
      // 用法示例

      const storedEndTime = date
      console.log(storedEndTime, 'aaaaaaaaaaaaa')
      // localStorage.getItem('endTime');
      if (storedEndTime) {
        this.endTime1 = parseInt(storedEndTime, 10);
      } else {
        this.startCountdown1(1 * 24 * 60 * 60); // 5天倒计时
      }
      this.updateCountdown1(date);
      // setInterval(this.updateCountdown, 1000);
      this.timer1 = setInterval(() => {
        //需要定时执行的代码
        this.updateCountdown1(date)
      }, 1000)
    },
    startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
    startCountdown1(duration) {
      this.endTime1 = Date.now() + duration * 1000;
      localStorage.setItem('endTime1', this.endTime1);
    },
    updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.lastcalltimevalue = 0
          this.finishshow = true
          clearInterval(this.timer)
        }
      }
      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown = 0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    updateCountdown1(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow1 == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow1 = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime1 - now <= 0) {
          console.log('结束')
          this.lastcalltimevalue = 0
          this.finishshow1 = true
          clearInterval(this.timer1)
        }
      }


      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown1 = 0
        return
      }

      this.countdown1 = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },
    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign
        .getauthorization(
          this.addresscode
        )
        .then((res) => {
          console.log(res, '签名验证')
          if (res.code == 0) {
            this.swapimg2 = this.swapswap_usdt_dai
            this.swapname = 'USDT'
            this.swap_decimalsA = 6;
            this.swap_Aaddress = this.sitedata.USDT_swap.address
            this.swap_aimg = this.swap_usdt
            this.swap_Aname = 'USDT'
            this.users()

          }
          this.isloading = false;
        });
    },
    async getDepositData() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .getDepositData(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,

          )
          .then((res) => {
            console.log(res,'getDepositData')
         this.getDepositDatadata=res.data
          });
      },
      async getMyUserInterest() {
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.aave_token.address;
        await contract
          .getMyUserInterest(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals,

          )
          .then((res) => {
            console.log(res,'getMyUserInterest')
            this.getMyUserInterestdata=res.data
          });
      },
    async getMyUserBurnDayInterest() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('Personal income')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.getMyUserBurnDayInterest(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.swap_Aaddress
      ).then((res) => {
        console.log(res, 'getMyUserBurnDayInterest');
        this.getMyUserBurnDayInterestdata = res.data * 1

      });
    },
    onChange(e) {
      console.log(this.slidervalue)

      this.exchangeallowance()


    },
    inputclick() {
      if (this.swap_Aname != 'DAI') {
        if (this.daiValue > this.formattedMyInvestAmount) {
          this.daiValue = this.formattedMyInvestAmount
        }
      } else {
        if (this.daiValue > this.formattedDaiOwnMoney) {
          this.daiValue = this.formattedDaiOwnMoney
        }
      }
      // if (this.daiValue > 0) {
      //   this.getWithdrawFalconToken()
      // } else {
      //   this.getWithdrawFalconTokenfalconAmount = 0
      // }
    },
    async getWithdrawFalconToken() {
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.aave_token.address;
      await aaveChild.getWithdrawFalconToken(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals,
        this.daiValue
      ).then((res) => {
        console.log(res, 'getWithdrawFalconToken');
        this.getWithdrawFalconTokenfalconAmount = res.data.falconAmount

      });
    },
    // User information
    async users() {
      this.isloading = true;
      //console.log(this.sitedata)
      let _decimals = 18;
      let ContractAddress;
      //console.log('User information')
      ContractAddress = this.sitedata.aave_token.address;
      await contract.users(
        this.addresscode,  //Current user address
        ContractAddress,  //Call contract
        _decimals
      ).then((res) => {
        this.isloading = false;
        console.log(res, 'User information');
        this.usersdata = res.data
        this.daiOwnMoney = res.data.investAmount * 1
        // this.getMyUserBurnDayInterest()
        this.getDepositData()
        this.getMyUserInterest()
        this.burnUsers()
        this.balance2()
        this.exchangeallowance()


        // this.nowDepositWithdrawToken()
        // console.log(this.stakedMoney)
        // const total = 14000;
        // this.currentProgress = (this.stakedMoney / total) * 100;
      });
    },
    // 是否授权
    async exchangeallowance() {
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address;
      spender = this.sitedata.aave_token.address;
      await Plugens.allowancetoken(
        Address,
        0,
        this.addresscode,
        spender,
        _decimals
      ).then((result) => {
        console.log(result, 'falcon_swap是否授权1111')
        this.isloading = false;
        if (result.data * 1 >= this.daiValue2) {
          this.falconshow = true;
        } else {
          this.falconshow = false;
        }

      });
    },
    async Approve() {
      this.isloading = true; // close loading
      let _decimals = 18;
      let Address;
      let spender;
      Address = this.sitedata.falcon_swap.address;
      spender = this.sitedata.aave_token.address;
      this.typeApprove = 1; // Approveing
      try {
        await Plugens.approveTokens2(
          Address, //
          this.daiValue2, // DAI number
          this.addresscode,
          spender,
          _decimals
        ).then((res) => {
          // console.log(res);
          this.typeApprove = 0;
          if (res.code == 0) {
            this.typeApprove = 0;
            this.$Notice.success({
              title: this.$t("stake.AuthorSuccessful")
              // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
            });
            this.isloading = false; // close loading
            this.falconshow = true;
          } else {
            this.$Notice.warning({
              title: this.$t("stake.AuthorFailed")
              //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
            });
            this.isloading = false;
          }
        });
      } catch (error) {

        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("stake.AuthorFailed"),
          // desc: this.$t("stake.AuthorFailed"),
        });
        // console.log(error);
      }
    },



    async falconTokenUsers() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .tokenUsers(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.sitedata.falcon_swap.address
        )
        .then((res) => {
          console.log(res, 'falconTokenUsers')
          this.falconTokenUsersRewardAmount = res.data.rewardAmount * 1
        });
    },




    async nowDepositWithdrawToken() {
      this.isloading = true;
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .nowDepositWithdrawToken(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals,
          this.swap_Aaddress
        )
        .then((res) => {
          this.isloading = false;
          console.log(res, 'nowDepositWithdrawToken', this.sitedata)
          var value = res.data.toLowerCase()
          console.log(value, 'valuevaluevaluevalue', this.sitedata.USDC_swap.address)

          this.swap_Aaddress = value
          if (value == this.sitedata.DAI_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_dai
            this.swap_aimg = this.swap_dai
            this.swap_Aname = 'DAI'
            this.swap_decimalsA = 18;
            this.swapname = 'DAI'
          } else if (value == this.sitedata.USDT_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_usdt
            this.swap_aimg = this.swap_usdt
            this.swap_Aname = 'USDT'
            this.swap_decimalsA = 6
            this.swapname = 'USDT'
          } else if (value == this.sitedata.USDC_swap.address.toLowerCase()) {
            this.swapimg2 = this.swap_usdc
            this.swap_aimg = this.swap_usdc
            this.swap_Aname = 'USDC'
            this.swap_decimalsA = 6
            this.swapname = 'USDC'
          }

          // this.stakedMoney = res.data.investAmount * 1;
        });
    },
    // 选择币种
    async onselect(img, name, site, _decimals, swaptype) {
      this.swap_decimalsA = _decimals
      this.swap_aimg = img
      this.swap_Aname = name
      this.swap_Aaddress = this.sitedata[site].address
      console.log(this.sitedata[site].address, 'AAAAAAAAAAAAAA')
      //             this.balance()
      // this.exchangeallowance()
      this.isShown2 = false
      this.users()


      // this.getMyUserBurnDayInterest()
      this.burnGetMyUserBurnInterest()
      this.burnUsers()
      this.balance2()
      this.exchangeallowance()

    },




    async burnUsers() {
        this.exchangeallowance();
    },

    inputvalue2(e) {
      // if(this.daiValue2<=this.daiValue2small){
      //   this.daiValue2=this.daiValue2small
      // }
      this.exchangeallowance();
    },

    funvalue(num) {
      return Math.round((num + Number.EPSILON) * 100) / 100;
    },
    // 激活余额
    async balance2() {
      var that = this
      let _decimals = 18;
      let spender;
      let Address;
      Address = this.sitedata.falcon_swap.address
      await Plugens.balanceOf(
        Address,
        0,
        this.addresscode,

        _decimals
      ).then((result) => {
        console.log(result, "falcon余额");
        this.daiOwnMoneyjh = result.data
      });
    },
    //withdraw button
    withdraw() {
      if (this.daiValue <= 0) {

        this.$Notice.warning({
          title: this.$t("Withdraw.WithdrawInfo1")
        });
        return
      }
      // if (this.daiValue > this.daiOwnMoney) {

      //   this.$Notice.warning({
      //     title: this.$t("Withdraw.WithdrawInfo2")
      //   });
      //   return
      // }
      this.isloading = true;
      this.withdrawfun();
      // this.warning(false);
    },

    async burnDepositToken() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      let falconAmount;
      Address = this.sitedata.aave_token.address;
      this.typeBurn = 1;
      try {
        var asdas = this.slidervalue;


        if (this.falconAmount - this.daiValue2 > 0) {
          falconAmount = 0;
        } else {
          falconAmount = this.daiValue2 - this.falconAmount;
        }

        falconAmount =
          await contract.burnDepositToken(
            Address, //Contract address
            this.addresscode,//Current address
            _decimals,//Fractional part
            this.sitedata.aave_token.address,
            asdas,
            falconAmount,
            this.swap_Aaddress,
          ).then((res) => {
            console.log(res);
            this.typeBurn = 0;
            if (res.code == 0) {
              this.typeBurn = 0;
              this.isloading = false;
              this.show = 1
              this.$Notice.success({
                title: this.$t("Withdraw.successful")

              });

              // this.getMyUserBurnDayInterest()


              this.burnUsers()
              this.balance2()
              this.exchangeallowance()


            } else {
              // this.typeApprove = 1;
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("Withdraw.unsuccessfule")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }

          });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    // withdraw
    async withdrawfun() {
      let _decimals = this.swap_decimalsA;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeextract = 1;
      try {
        await contract.withdraw(
          Address, //Contract address
          this.daiValue, //quantity
          this.addresscode,//Current address
          _decimals,//Fractional part

        ).then((res) => {
          console.log(res);
          this.typeextract = 0;
          if (res.code == 0) {
            this.typeextract = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")

            });
            this.users()

          } else {
            // this.typeApprove = 1;
            this.isloading = false;
            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        this.isloading = false;
        // this.typeApprove = 1;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    // reinvestment

    // withdraw
    async withdrawInterestAndReward() {
      this.isloading = true;
      let _decimals = 18;
      let Address;
      Address = this.sitedata.aave_token.address;
      this.typeReceive = 1;
      try {
        await contract.withdrawInterestAndReward(
          Address, //Contract address
          this.daiValue, //number
          this.addresscode,//current address
          _decimals,//float

        ).then((res) => {
          //console.log(res);
          this.typeReceive = 0;
          if (res.code == 0) {
            this.typeReceive = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()

          } else {
            // this.typeApprove = 1;
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }

        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },
    awaitinterest() {
      this.interest();
    },
    async interest() {
      this.isloading = true;
      let Address;
      Address = this.sitedata.aave_token.address;
      //this.typeReceive = 1;
      try {
        await contract.interest(
          Address, //Contract address
          this.addresscode,//current address
          this.swap_Aaddress
        ).then((res) => {
          //console.log(res);
          //this.typeReceive = 0;
          if (res.code == 0) {
            //this.typeReceive = 0;
            this.isloading = false;
            this.$Notice.success({
              title: this.$t("Withdraw.successful")
            });
            this.users()
          } else {
            // this.typeApprove = 1;
            this.isloading = false;

            if (res.code < 1) {
              this.$Notice.warning({
                title: res.error.message
              });
            } else {
              this.$Notice.warning({
                title: this.$t("Withdraw.unsuccessfule")
                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
              });
            }
          }
        });
      } catch (error) {
        // this.typeApprove = 1;
        this.isloading = false;
        this.$Notice.warning({
          title: this.$t("Withdraw.unsuccessfule")
        });
        //console.log(error);
      }
    },


    warning(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.withdraw"),
      });
    },
    warning2(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Reinvest"),
      });
    },
    warning3(nodesc) {
      this.$Notice.warning({
        title: "FlashMonkey",
        desc: nodesc ? "" : this.$t("network.Getreward"),
      });
    },
  },

  computed: {

    formattedfInvestAmount() {
      if (!this.tokenUsersFInvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersFInvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },

    formattedDaiOwnMoney2() {
      if (!this.tokenUsersinvestAmount) return "0.0000";
      const multipliedNumber = this.tokenUsersinvestAmount * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result);
      //     console.log(this.stakedMoney);
      let value = parseFloat(result);
      //console.log(value)
      return value
    },
    formattedDaiOwnMoney() {
      if (!this.daiOwnMoney) return "0.00";
      const multipliedNumber = this.daiOwnMoney * 100000;
      const truncatedNumber = Math.trunc(multipliedNumber);
      const result = truncatedNumber / 100000;
      //console.log(result)
      //console.log(this.daiOwnMoney)
      let value = parseFloat(result);
      return value
    },
  },
};
</script>
